<template>
  <v-form autocomplete="off" ref="form">
    <h2 class="custom-title-secondary">
      {{ title }}
    </h2>
    <p class="custom-normal-text font-weight-bold text-blue">
      {{ subtitle }}
    </p>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          validate-on-blur
          label="Nome completo / nome empresarial "
          v-model="companyPaymentInfo.name"
          :rules="rules.name"
          required
          @change="saveName"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          ref="CpfCnpj"
          validate-on-blur
          label="Escreva o CPF ou CNPJ"
          v-model="companyPaymentInfo.cpfCnpj"
          :rules="rules.cpfCnpj"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          required
          @change="saveCpfCnpj"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <k-address></k-address>
      </v-col>
      <v-col cols="12" class="mb-5">
        <base-agree-checkbox
          label="Aceito o contrato de prestação de serviço"
          :rules="rules.aceptContract"
          v-model="agree"
        >
          <service-contract
            :typeofPlan="
              companyPaymentInfo.currentSubscription.plan.classification
            "
          ></service-contract>
        </base-agree-checkbox>
      </v-col>

      <v-col cols="12" sm="8" md="4" align-self="center">
        <v-btn
          block
          class="custom-btn-green rounded-0"
          @click="goToPaymentSummary()"
          :loading="loading"
        >
          {{ checkIfPlanIsFree ? 'Salvar e Prosseguir' : 'Ir para pagamento' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { paymentComputed, paymentMethods } from '@state/helpers'
import { email } from '@src/services/rules'
import kAddress from './formAddress.vue'
import ServiceContract from '@src/components/contracts/ServiceContract.vue'

import cnpj from '@src/services/cnpj'
import BrV from 'br-validations'

export default {
  name: 'payment-info',
  data() {
    return {
      isMounted: false,
      copyFrom: null,
      showError: false,
      agree: null,
      loading: false,
      rules: {
        email,
        name: [
          (v) => !!v || 'preencha com o seu nome completo ou nome empresarial',
        ],
        cpfCnpj: [
          (v) => !!v || 'preencha com o cpnf ou cnpj',
          (v) => {
            v += ''
            if (v.length === 11 || v.length === 14) {
              return (
                BrV.cpf.validate(v || '') ||
                'Por favor preencha com um cpf válido.'
              )
            }
            if (v.length === 14 || v.length === 18) {
              return cnpj.validate(v) || 'CNPJ inválido.'
            }
            return !(v.length < 18) || 'CPF ou CNPJ está incompleto'
          },
        ],
        aceptContract: [
          (v) =>
            !!v || 'É necessário aceitar o contrato de prestação de serviço',
        ],
      },
    }
  },
  computed: {
    ...paymentComputed,
    subtitle() {
      if (this.checkIfPlanIsFree)
        return 'Insira os dados do responsável pela contratação'
      return 'Insira os dados do responsável pelo pagamento'
    },
    title() {
      if (this.checkIfPlanIsFree)
        return 'Informações do responsável pela contratação'
      return 'Informações do responsável pelo pagamento'
    },
  },
  methods: {
    ...paymentMethods,
    saveName(v) {
      this.saveCompanyPaymentInfoField({ name: v })
    },
    saveEmail(v) {
      this.saveCompanyPaymentInfoField({ email: v })
    },
    async saveCpfCnpj(v) {
      let value = { cpfCnpj: v.replace(/\D/g, '') }
      await this.saveCompanyPaymentInfoField(value)
      this.$nextTick(() => {
        this.$refs.CpfCnpj.$emit('input')
      })
    },
    async goToPaymentSummary() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      await this.saveCompanyPaymentInfoField({ step: 2 })
      this.loading = false
    },
  },
  components: {
    kAddress,
    ServiceContract,
  },
}
</script>

<style lang="scss" scoped>
.no-box-shadow {
  box-shadow: none;
}
</style>
