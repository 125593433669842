<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-text-field
        validate-on-blur
        ref="cep"
        label="CEP"
        :rules="cepRules"
        v-mask="cepMask"
        @input="
          companyPaymentInfo.address.cep = $options.filters.unmaskText($event)
        "
        :value="companyPaymentInfo.address.cep"
        @change="searchCEP"
        required
        :loading="companyPaymentInfoLoadingCep"
        :readonly="companyPaymentInfoLoadingCep"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        ref="street"
        validate-on-blur
        label="Rua, Avenida, Alameda..."
        hint="Preencha o endereço"
        v-model="companyPaymentInfo.address.address"
        :rules="rules.address"
        :loading="companyPaymentInfoLoadingCep"
        @change="saveAddress"
        required
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        ref="number"
        validate-on-blur
        label="Número"
        hint="Número do endereço"
        v-model="companyPaymentInfo.address.addressNumber"
        :rules="rules.addressNumber"
        @change="saveAddressNumber"
        required
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Complemento"
        hint="O complemento do endereço, exemplo: AP12"
        v-model="companyPaymentInfo.address.addressComplement"
        @change="saveAddressComplement"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Bairro"
        v-model="companyPaymentInfo.address.district"
        :rules="rules.district"
        :loading="companyPaymentInfoLoadingCep"
        required
        @change="saveDistrict"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-select
        label="Estado"
        item-text="name"
        item-value="name"
        return-object
        @input="companyPaymentInfo.address.state = $event.name"
        :value="companyPaymentInfo.address.state"
        :items="states"
        :loading="stateLoading || companyPaymentInfoLoadingCep"
        :rules="rules.state"
        required
        ref="state"
        @change="stateChanged"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-select
        label="Cidade"
        @input="companyPaymentInfo.address.city = $event.name"
        :value="companyPaymentInfo.address.city"
        :items="cities"
        :rules="rules.city"
        :loading="cityLoading || companyPaymentInfoLoadingCep"
        item-text="name"
        return-object
        required
        ref="city"
        @change="saveCity"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import cep from '@src/services/cep'
import {
  address,
  addressNumber,
  district,
  state,
  city,
} from '@src/services/rules'

import {
  paymentComputed,
  paymentMethods,
  cepMethods,
  cepComputed,
  stateMethods,
  stateComputed,
  cityMethods,
  cityComputed,
} from '@state/helpers'
export default {
  name: 'payment-address',
  data() {
    return {
      rules: {
        cep: cep.rules,
        address,
        addressNumber,
        district,
        state,
        city,
      },
    }
  },
  computed: {
    ...paymentComputed,
    ...cepComputed,
    ...stateComputed,
    ...cityComputed,
  },
  methods: {
    ...cityMethods,
    ...paymentMethods,
    ...cepMethods,
    ...stateMethods,
    searchCEP(v) {
      if (!v) return false
      this.setCompanyPaymentsInfoLoadingCep(true)
      const value = this.$options.filters.unmaskText(v)
      this.search(value)
        .then((resp) => {
          let data = this.modelState(resp, value)
          this.validate()
          this.saveCompanyPaymentInfoField(data)
          this.$store.dispatch('city/getCities', { stateId: data.idState })
          this.setCompanyPaymentsInfoLoadingCep(false)
        })
        .catch(() => {
          this.setCompanyPaymentsInfoLoadingCep(false)
          this.saveCompanyPaymentInfoField({
            cep: value,
            cepNotFound: true,
          })
        })
    },
    modelState(data, cep) {
      return {
        idState: data.idState,
        idCity: data.idCity,
        address: data.logradouro,
        district: data.bairro,
        cep,
        cepNotFound: false,
      }
    },
    validate() {
      this.$refs.cep.validate()
      this.$refs.street.validate()
      this.$refs.number.validate()
      this.$refs.state.validate()
      this.$refs.city.validate()
    },
    saveAddress(v) {
      this.validate()
      this.saveCompanyPaymentInfoField({
        address: v,
      })
    },
    saveAddressComplement(v) {
      this.validate()
      this.saveCompanyPaymentInfoField({
        addressComplement: v,
      })
    },
    saveAddressNumber(v) {
      this.validate()
      this.saveCompanyPaymentInfoField({
        addressNumber: v,
      })
    },
    saveDistrict(v) {
      this.validate()
      this.saveCompanyPaymentInfoField({
        district: v,
      })
    },
    async stateChanged(v) {
      if (!v) return
      if (!this.migrationCompanyCepNotFound) return
      this.validate()
      await this.saveCompanyPaymentInfoField({
        idState: v.id,
      })
      this.$store.dispatch('city/getCities', { stateId: v.id })
    },
    saveCity(v) {
      if (!this.migrationCompanyCepNotFound) return
      this.validate()
      this.saveCompanyPaymentInfoField({
        idCity: v.id,
      })
    },
  },
  created() {
    this.getAllStates()
  },
  watch: {
    'companyPaymentInfo.address.state': {
      handler(newValue) {
        if (!newValue) return
        this.getCityByStateName(this.companyPaymentInfo.address.state)
      },
      immediate: true,
    },
  },
}
</script>
