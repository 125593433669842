import axios from '@plugins/axios'

const baseURL = 'cep'

export default {
  mask() {
    return '#####-###'
  },
  search(cep) {
    return axios.get(`${baseURL}/${cep}`)
  },
  rules: [
    (v) => !!v || 'Por favor, preencha o CEP.',
    (v) => {
      v += ''
      return (
        v.length === 8 ||
        v.length === 9 ||
        'Por favor preencha com um CEP válido.'
      )
    },
  ],
}
